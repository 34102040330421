import React, {useEffect, useRef, useState} from 'react';
import './treeSelector.css'

const TreeNode = ({ node, onSelect }) => {
    const [expanded, setExpanded] = useState(false);

    const hasChildren = node.children && node.children.length > 0;

    const handleClick = () => {
        if (hasChildren) {
            setExpanded(!expanded);
        } else {
            onSelect(node);
        }
    };

    return (
        <div className="tree-select-node">
            <div onClick={handleClick} style={{ cursor: "pointer", userSelect: "none" }}>
                {hasChildren ? (expanded ? "▼  " : "▶  ") : "•  "}
                {node.name}
            </div>
            {expanded &&
                hasChildren &&
                node.children.map((child) => (
                    <TreeNode key={child.id} node={child} onSelect={onSelect} />
                ))}
        </div>
    );
};

const TreeSelector = ({ data, onSelect }) => {

    const wrapperRef = useRef(null);
    const [ isFocused, setFocused ] = useState(false);
    const [ selectedName, setSelectedName ] = useState("");

    const inputClass = "input tree-input" + (isFocused ? " open" :"");

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setFocused(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleSelect = (selected) => {
        setFocused(false);
        setSelectedName(selected.name)
        onSelect(selected);
    }

    return (
        <div ref={wrapperRef} className={inputClass} onClick={() => { if(!isFocused) setFocused(true) }}>
            {isFocused ?
                <div className="input tree-dropdown focus">
                    {data.map((node) => (
                        <TreeNode key={node.id} node={node} onSelect={handleSelect}/>
                    ))}
                </div> : <></>
            }
            {selectedName && selectedName !== "" ?
                <p>{selectedName}</p> :
                <p style={{color: "transparent"}}>spacing</p>
            }

        </div>
    );
};

export default TreeSelector;

