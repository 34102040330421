import Form from "../../form/Form";
import FormHeading from "../../form/FormHeading";
import FormInput from "../../form/FormInput";
import {useRef, useState} from "react";
import FormText from "../../form/FormText";
import FormButton from "../../form/FormButton";
import DialogOld from "../../general/dialog/DialogOld";
import NewProductValidation from "../../../logic/vendor/newProductValidation";
import Popup from "../../../../components/general/popup/Popup";
import useAuthRequest from "../../../services/state/request/useAuthRequest";
import newProductRequest from "../../../services/rest/request/vendor/newProductRequest";
import FormCheckBox from "../../form/FormCheckBox";
import FormTextArea from "../../form/FormTextArea";
import FormItem from "../../form/FormItem";
import FormFileUpload from "../../form/FormFileUpload";
import {useSelector} from "react-redux";
import TreeSelector from "../../general/input/treeSelector/TreeSelector";
import LoadingSwitcher from "../../general/loading/LoadingSwitcher";
import {condition, request} from "../../../../res/rest/restRequest";
import {env} from "../../../../res/config/env";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import Selector from "../../general/input/Selector";

const getTaxCategories = (session) => {
    return request.model("c_taxcategory")
        .filter(condition.eq("ad_client_ID", env.AD_CLIENT_ID))
        .get(session)
}

const useTaxCategories = () => {
    const { loading, sendIt, result } = useAuthRequest();
    useValueChangeListener(() => {
        sendIt(getTaxCategories)
    }, [1], [])

    return {
        loading,
        result
    }
}

const NewProductForm = () => {

    const [ warning, setWarning ] = useState();
    const { loading, sendIt, result } = useAuthRequest();
    const { loading: loadingTax, result: tax} = useTaxCategories();

    const { categoryMap, loading: loadingCategories } = useSelector(
        state => state.session.category
    );

    const ref = useRef({});

    const setRefValue = (key) => (val) => {
        ref.current[key] = val;
    }


    const submit = () => {
        const valid = NewProductValidation.validate(ref.current);
        if(!valid.isValid) {
            setWarning(valid.msg);
        } else {
            const req = newProductRequest()
                .data(ref.current);
            sendIt(req.sendIt);
        }
    }

    if(result) {
        return (
            <>
                <Form>
                    <FormText name="Form Submitted">
                        Thank you! Your form has been submitted. A member of our team will review the form and get back to you promptly.
                    </FormText>
                    <div style={{marginBottom: "200px"}} />
                </Form>
            </>
        )
    }

    return (
        <>
            {loading &&
                <Popup>
                    <DialogOld
                        title="Submitting Form"
                        message="Submitting form please wait"
                        showLoading
                    />
                </Popup>
            }
            {warning &&
                <Popup>
                    <DialogOld
                        title="Missing Data"
                        message={warning}
                        neutralButton={{name: "OK", onClick: () => setWarning(null)}}
                    />
                </Popup>
            }
            <Form>
                <FormHeading gridColumn="span 2">Basic Product Information</FormHeading>
                <FormInput name="Shortened Name*" onValueChange={setRefValue("name")}/>
                <FormInput name="Full Name (Displayed on Webstore)*" onValueChange={setRefValue("description")}/>
                <FormItem>
                    <p className="input-form-sub-heading">Product Category*</p>
                    <LoadingSwitcher loading={loadingCategories}>
                        <TreeSelector onSelect={c => setRefValue("M_Product_Category_ID")(c.id)} data={Object.values(categoryMap)} />
                    </LoadingSwitcher>
                </FormItem>
                <FormTextArea
                    gridColumn="span 2"
                    name="Meta Keywords (Used For Webstore Search)"
                    onValueChange={setRefValue("Meta_Keywords")}
                />
                <FormItem>
                    <p className="input-form-sub-heading">Product Branding</p>
                    <FormText>If your product is listed with NIQ brandbank, we will be able to import branding data from
                        there. <b>If your product is not listed with NIQ brandbank, please upload a product image below.</b>
                    </FormText>
                    <FormFileUpload accept="image/*" onFileUploaded={() => {}}/>
                    <div style={{height: 20}}/>
                </FormItem>
            </Form>
            <Form>
                <FormHeading gridColumn="span 2">Packaging and Unit Information</FormHeading>
                <FormInput name="Retail Product Unit (e.g. 75cl, 250g, 24x35g)*" onValueChange={setRefValue("X_ProductUnit")} />
                <FormInput name="Order Pack Size*" onValueChange={setRefValue("Order_Pack")} />
                <FormInput name="Pallet Quantity" onValueChange={setRefValue("UnitsPerPallet")} />
                <FormInput name="Minimum Order Quantity" onValueChange={setRefValue("Order_Min")} />
                <FormInput name="Layer Quantity" onValueChange={setRefValue("layerqty")} />
            </Form>
            <Form>
                <FormHeading gridColumn="span 2">Identification & Codes</FormHeading>
                <FormInput name="Supplier Code*"  onValueChange={setRefValue("VendorProductNo")} />
                <FormInput name="Case Barcode*"  onValueChange={setRefValue("casebarcodecurrent")} />
                <FormInput name="Retail Barcode*" onValueChange={setRefValue("itembarcodecurrent")} />
            </Form>
            <Form>
                <FormHeading gridColumn="span 2">Pricing & Tax</FormHeading>
                <FormInput name="Cost Price*" onValueChange={setRefValue("PricePO")} />
                <FormInput name="RRP*" onValueChange={setRefValue("PriceStd")} />
                <FormCheckBox text="Price Marked" onCheckedChanged={setRefValue("isPriceMark")} />
                <FormItem>
                    <p className="input-form-sub-heading">VAT Rate*</p>
                    <LoadingSwitcher loading={loadingTax}>
                        <Selector
                            options={[{id:0, Name: ""}, ...(tax ? tax : [])]}
                            onSelect={(t) => setRefValue("C_TaxCategory_ID")(t.id)}
                            optionToString={t => t.Name}
                        />
                    </LoadingSwitcher>
                </FormItem>
            </Form>
            <Form>
                <FormHeading gridColumn="span 2">Dimensions & Weight</FormHeading>
                <FormInput name="Weight (kg)" onValueChange={setRefValue("Weight")} />
                <FormInput name="Height (cm)" onValueChange={setRefValue("ShelfHeight")} />
                <FormInput name="Width (cm)"  onValueChange={setRefValue("ShelfWidth")} />
                <FormInput name="Depth (cm)"  onValueChange={setRefValue("ShelfDepth")} />
            </Form>
            <Form>
                <FormHeading gridColumn="span 2">Other</FormHeading>
                <FormInput name="Comments/ Notes" onValueChange={setRefValue("DocumentNote")} />
            </Form>
            <Form>
                <div style={{height: "100px", gridColumn: 2}}></div>
                <FormButton gridColumn={2} onClick={submit}>Submit For Review</FormButton>
            </Form>
            <div style={{height: "100px"}} />
        </>
    )

}

export default NewProductForm;
